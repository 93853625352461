import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.allTransactionsTable, _ctx.inboundTransactionsTable, _ctx.outboundTransactionsTable],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": "",
        "side-pane-options": {
                hideHeader: true,
            }
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            style: {"width":"200px"},
            disabled: !_ctx.Permissions.PARTNER_ENGAGEMENT.canOrderPackaging(),
            "disabled-message": "You do not have permission to order packaging",
            onClick: _ctx.openAddOrder
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.orderPackaging')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            style: {"width":"200px"},
            disabled: !_ctx.Permissions.PARTNER_ENGAGEMENT.canAddShipment(),
            "disabled-message": "You do not have permission to add a shipment",
            onClick: _ctx.openAddShipment
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addShipment')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                onClick: () => _ctx.openTransaction(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation(_ctx.canEditTransaction(row.item.transactionDigest) ? 'core.button.edit' : 'core.button.view')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: _ctx.isCancelDisabled(row.item.transactionDigest),
                onClick: () => _ctx.openCancel(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancelOrder')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: _ctx.isBillOfLadingDisabled(row.item.status),
                onClick: () => _ctx.getTransactionBillOfLading(row.item)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printBillOfLading')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        "cell(shipDate)": _withCtx((row) => [
          (row.item.shipDate)
            ? (_openBlock(), _createBlock(_component_simple_date_popover, {
                key: 0,
                date: row.item.shipDate
              }, null, 8, ["date"]))
            : _createCommentVNode("", true)
        ]),
        "cell(placementDate)": _withCtx((row) => [
          _createVNode(_component_simple_date_popover, {
            date: row.item.placementDate
          }, null, 8, ["date"])
        ]),
        "cell(deliveryDate)": _withCtx((row) => [
          (row.item.deliveryDate)
            ? (_openBlock(), _createBlock(_component_simple_date_popover, {
                key: 0,
                date: row.item.deliveryDate
              }, null, 8, ["date"]))
            : _createCommentVNode("", true)
        ]),
        "cell(arrivedAt)": _withCtx((row) => [
          (row.item.arrivedAt)
            ? (_openBlock(), _createBlock(_component_simple_date_popover, {
                key: 0,
                date: row.item.arrivedAt
              }, null, 8, ["date"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}